// import NFTContractBuild from 'contracts/NFT.json';
import { constants } from "ethers";
import Web3 from "web3";
import { Abi } from "./Components/Dashboard/abi";

let selectedAccount;

// let nftContract;
let erc721Contract;

let isInitialized = false;

export var action = "connect wallet";

let web3;

export var status = "not connected";

export const init = async () => {
  let provider = window.ethereum;

  if (typeof provider !== "undefined") {
    provider
      .request({ method: "eth_requestAccounts" })
      .then((accounts) => {
        selectedAccount = accounts[0];
        console.log(`Selected account is ${selectedAccount}`);
      })
      .catch((err) => {
        console.log(err);
        return;
      });

    window.ethereum.on("accountsChanged", async function (accounts) {
      selectedAccount = await Web3.utils.toCheckAddressChecksum(accounts[0]);
      console.log(`Selected account changed to ${selectedAccount}`);
    });

    if ("eth_chainId" != "0x28") {
      await switchNetwork();
    }
  }

  web3 = new Web3(provider);

  const networkId = await web3.eth.net.getId();

  const currentChainId = window.ethereum.request({
    method: "eth_chainId",
  });

  erc721Contract = new web3.eth.Contract(
    Abi,
    "0xDceb42c8A66ff5d3c76A23e7c5656c1121594F3e"
  );

  isInitialized = true;
};

// switches network to the one provided
export const switchNetwork = async () => {
  await window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: "0x28",
        chainName: "Telos EVM Mainnet",
        nativeCurrency: {
          name: "Telos",
          symbol: "TLOS",
          decimals: 18,
        },
        rpcUrls: ["https://mainnet.telos.net/evm"],
        blockExplorerUrls: ["https://teloscan.io"],
      },
    ],
  });
  await window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: "0x28" }],
  });
  // refresh
};

export const connectWallet = async () => {
  if (!isInitialized) {
    await init();
  }
};

export const getMintPrice = async () => {
  if (!isInitialized) {
    await init();
  }
  return erc721Contract.methods.MINT_PRICE().call();
};

export const mintNFT = async () => {
  if (!isInitialized) {
    await init();
  }
  if ("eth_chainId" != "0x28") {
    await switchNetwork();
  }
  const price = await getMintPrice();
  await erc721Contract.methods
    .Mint()
    .send({
      from: selectedAccount,
      gasLimit: 3000000,
      value: price,
    })
    .then("transactionHash", function (hash) {
      return hash;
    });
};
export const getSupply = async () => {
  if (!isInitialized) {
    await init();
  }
  return erc721Contract.methods.totalSupply().call();
};

export const adminLogin = async () => {
  if (!isInitialized) {
    await init();
  }
  const owner = await erc721Contract.methods.owner().call();
  if (owner === selectedAccount) {
    const signature = await web3.eth.personal.sign("Admin login", owner);
    return owner;
  }
  console.log(`Owner address is ${owner}`);
  return false;
};
