import React, { useState } from "react";
import "../Dashboard/Dashboard.css";
import Web3 from "web3";
import { Abi } from "./abi";
import { constants } from "ethers";
import { adminLogin } from "../../Web3Client";

const Dashboard = () => {
  const contractAbi = Abi;
  let provider = window.ethereum;

  const web3 = new Web3(provider);

  const smartContract = new web3.eth.Contract(
    contractAbi,
    "0xDceb42c8A66ff5d3c76A23e7c5656c1121594F3e"
  );

  const [adminAddress, setAdminAddress] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [totalSupply, setTotalSupply] = useState("Login");
  const [contractBalance, setContractBalance] = useState("Login");
  const [mintPrice, setMintPrice] = useState("");
  const [minted, setMinted] = useState(false);
  const [withdrawn, setWithdrawn] = useState(false);

  const login = async () => {
    console.log("Logging in");
    try {
      const admin = await adminLogin();
      if (admin !== false) {
        setLoggedIn(true);
        const supply = await smartContract.methods.totalSupply().call();
        setTotalSupply(supply);
        const balance = await web3.eth.getBalance(
          "0xDceb42c8A66ff5d3c76A23e7c5656c1121594F3e"
        );
        setContractBalance(Web3.utils.fromWei(balance, "ether"));
        const price = await smartContract.methods.MINT_PRICE().call();
        setMintPrice(Web3.utils.fromWei(price, "ether"));
        setAdminAddress(admin);
      } else {
        alert("Access denied");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const selfMint = async () => {
    try {
      await smartContract.methods
        .ownerMint() //selectedAccount)
        .send({ from: adminAddress, gasLimit: 200000 });
      alert("Mint Successful");
    } catch (error) {
      console.log(error.message);
    }
  };

  const setMintCost = async () => {
    try {
      const newPrice = prompt("Enter new mint price");
      await smartContract.methods
        .changePrice(Web3.utils.toWei(newPrice, "ether"))
        .send({ from: adminAddress, gasLimit: 200000 });
      alert("Successful");
    } catch (error) {
      console.log(error.message);
    }
  };

  const withdraw = async () => {
    try {
      await smartContract.methods
        .withdraw()
        .send({ from: adminAddress, gasLimit: 200000 });
      alert("Successful");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <section className="ftco-section" id="buttons">
      <div className="row mb-4">
        <div className="col-md-12">
          <h2 className="heading-section"> Telos Kitties Admin Dashboard</h2>
        </div>
      </div>
      {!loggedIn ? (
        <button
          type="button"
          className="btn mb-2 mb-md-0 py-3 px-4 btn-quarternary"
          onClick={login}
        >
          Login
        </button>
      ) : (
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-12">
              <h2 className="heading-section">
                <small>Functions</small>
              </h2>

              <button
                type="button"
                className="btn mb-2 mb-md-0 py-3 px-4 btn-quarternary"
                onClick={login}
              >
                Read
              </button>
              <button
                type="button"
                className="btn mb-2 mb-md-0 py-3 px-4 btn-tertiary"
              >
                Write
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 mb-3">
              <button
                type="button"
                className="btn btn-quarternary w-100 align-items-stretch d-flex"
              >
                <div className="text text-left py-2 pl-3">
                  <h4>{`${totalSupply}`}</h4>
                  <span>Total Supply</span>
                </div>
                <div className="icon icon-right d-flex align-items-center justify-content-center">
                  <i className="ion-ios-stats"></i>
                </div>
              </button>
            </div>
            <div className="col-md-3 mb-3">
              <button
                type="button"
                className="btn btn-quarternary w-100 align-items-stretch d-flex"
              >
                <div className="text text-left py-2 pl-3">
                  <h4>{`${mintPrice}`} TLOS</h4>
                  <span>Mint Price</span>
                </div>
                <div className="icon icon-right d-flex align-items-center justify-content-center">
                  <i className="ion-ios-pricetags"></i>
                </div>
              </button>
            </div>

            <div className="col-md-3 mb-3">
              <button
                type="button"
                className="btn btn-quarternary w-100 align-items-stretch d-flex"
              >
                <div className="text text-left py-2 pl-3">
                  <h4>{`${contractBalance} TLOS`}</h4>
                  <span>Balance</span>
                </div>
                <div className="icon icon-right d-flex align-items-center justify-content-center">
                  <i className="ion-ios-wallet"></i>
                </div>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 mb-3">
              <button
                type="button"
                className="btn btn-tertiary w-100 align-items-stretch d-flex"
                onClick={selfMint}
              >
                <div className="icon icon-left d-flex align-items-center justify-content-center">
                  <i className="ion-ios-person-add"></i>
                </div>
                <div className="text text-right py-2 pr-3">
                  <h4>Owner Mint</h4>
                </div>
              </button>
            </div>
            <div className="col-md-3 mb-3">
              <button
                type="button"
                className="btn btn-tertiary w-100 align-items-stretch d-flex"
                onClick={setMintCost}
              >
                <div className="icon icon-left d-flex align-items-center justify-content-center">
                  <i className="ion-ios-settings"></i>
                </div>
                <div className="text text-right py-2 pr-3">
                  <h4>Update Price</h4>
                </div>
              </button>
            </div>
            <div className="col-md-3 mb-3">
              <button
                type="button"
                className="btn btn-tertiary w-100 align-items-stretch d-flex"
                onClick={withdraw}
              >
                <div className="icon icon-left d-flex align-items-center justify-content-center">
                  <i className="ion-ios-cash"></i>
                </div>
                <div className="text text-right py-2 pr-3">
                  <h4>Withdraw Balance</h4>
                </div>
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Dashboard;
