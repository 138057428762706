import React, { useState } from "react";
import "../Hero/Hero.css";
import META from "../Asset/metamask.svg";
import slideshow from "../Asset/roll.gif";
import Web3 from "web3";
import { mintNFT, init } from "../../Web3Client";
import { Abi } from "../Dashboard/abi";

const web3l = new Web3(
  new Web3.providers.HttpProvider("https://mainnet.telos.net/evm")
);

const TelosKittiesTK = new web3l.eth.Contract(
  Abi,
  "0xDceb42c8A66ff5d3c76A23e7c5656c1121594F3e"
);

const Hero = () => {
  const initialState = {
    number: "1",
  };

  const [total, setTotal] = useState("");
  const [statusofSale, setStatusofSale] = useState("OPEN");
  const [mintPrice, setMintPrice] = useState("loading");
  const [mintPriceEth, setMintPriceEth] = useState("loading");
  const [walletconnected, setWalletConnected] = useState(false);
  const [openmodal, setOpenModal] = useState(false);
  const [tokenmintSuccess, setTokenMintSuccess] = useState(false);
  const [txn, setTxn] = useState("");

  const getDetails = async () => {
    try {
      const counts = await TelosKittiesTK.methods.totalSupply().call();
      setTotal(counts);
      console.log(`Count is ${counts}`);
      const price = await TelosKittiesTK.methods.MINT_PRICE().call();
      setMintPrice(price);
      setMintPriceEth(Web3.utils.fromWei(price, "ether"));
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleTokenClose = () => {
    setTokenMintSuccess(false);
  };

  const connectMetamask = async () => {
    try {
      await init();
      setWalletConnected(true);
    } catch (error) {
      alert("MetaMask Not Detected");
    }
  };

  const MINT = async () => {
    try {
      const txn = await mintNFT();
      setTokenMintSuccess(txn);
      setTxn(txn);
    } catch (error) {
      if (
        String(error.message).includes(
          "Tx Signature: User denied transaction signature."
        )
      ) {
        alert("You aborted the transaction");
      } else if (
        String(error.message).includes(
          `No "from" address specified in neither the given options`
        )
      ) {
        alert("Error! make sure your metamask wallet is unlocked");
      } else {
        console.log(error.message);
      }
    }
  };

  return (
    getDetails(),
    (
      <div className="mintsection">
        <div className="mint-container" data-aos="fade-up">
          <div className="welcome">
            <div className="head">Mint A Telos Kitty</div>
            <div className="head-2">
              <span style={{ color: "white" }}>SALE: </span>
              <span style={{ color: "green" }}> ({statusofSale})</span>
              <br></br>
              <span style={{ color: "white" }}>PRICE: </span>
              <span style={{ color: "purple" }}>{mintPriceEth} TLOS </span>
            </div>
          </div>

          <div className="gif">
            <img width={300} height={300} src={slideshow} alt="" />
          </div>
          {tokenmintSuccess ? (
            <div className="tokenmint">
              <div className="head">Token Minted Successfully</div>

              <div className="contents">
                <div className="explorer">
                  <a
                    href={`https://tofunft.com/nft/telos/0xDceb42c8A66ff5d3c76A23e7c5656c1121594F3e/${
                      parseInt(total) + 1
                    }`}
                  >
                    View NFT
                  </a>
                </div>
                <div className="explorer">
                  <a
                    href={`https://teloscan.io/tx/${txn}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View on Teloscan
                  </a>
                </div>

                <div className="close" onClick={handleTokenClose}>
                  x
                </div>
              </div>
            </div>
          ) : null}
          <div className="mintbutton-value">
            {!walletconnected ? (
              <div>
                {openmodal ? (
                  <div className="mosdal">
                    <div className="modal-contents">
                      <div className="head">
                        <h5>Connect Wallet</h5>{" "}
                        <p onClick={handleCloseModal}>X</p>
                      </div>

                      <div className="wallets">
                        <div className="metamask" onClick={connectMetamask}>
                          <img width={80} src={META} alt="metamask" />
                          <p>Metamask</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <button className="modal-btn" onClick={handleOpenModal}>
                    Connect Wallet
                  </button>
                )}
              </div>
            ) : (
              <div className="mintbutton-value">
                <button className="modal-btn2" action="" onClick={MINT}>
                  Mint
                </button>
              </div>
            )}
            <div>
              <div className="reminants">
                <p style={{ color: "gold", fontWeight: "bolder" }}>
                  {total}/100
                </p>
              </div>
            </div>
          </div>

          <div className="contract-details">
            <div className="address">
              <div className="sop">CONTRACT ADDRESS:</div>
              <div className="blue">
                <a
                  href="https://teloscan.io/address/0xDceb42c8A66ff5d3c76A23e7c5656c1121594F3e"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  0xDceb42c8A66ff5d3c76A23e7c5656c1121594F3e
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Hero;

/*import React, { useState } from "react";
import { getMintPrice, getSupply, init } from "../../Web3Client";
import "../Hero/Hero.css";
import META from "../Asset/metamask.svg";
import Web3 from "web3";
import Popup from "reactjs-popup";

const Hero = ({ mint, minted }) => {
  const [walletConnected, setWalletConnected] = useState(false);
  const [openmodal, setOpenModal] = useState(false);
  const [mintPrice, setMintPrice] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [newToken, setNewToken] = useState(
    "https://tofunft.com/collection/telos-kitties/items"
  );

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const connectMetamask = async () => {
    await init();
    setWalletConnected(true);
  };

  const reflect = async () => {
    try {
      const supply = await getSupply();
      setTotalSupply(supply);
      const price = await getMintPrice();
      setMintPrice(Web3.utils.fromWei(price, "ether"));
      const nextToken = `https://tofunft.com/nft/telos/0xDceb42c8A66ff5d3c76A23e7c5656c1121594F3e/${
        supply + 1
      }`;
      setNewToken(nextToken);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    reflect(),
    (
      <div className="intro">
        <div className="intro-wrapper">
          <div className="intro-blur">
            <div className="hero-content">
              <div className="mint-nft">
                {!walletConnected ? (
                  <span>
                    <Popup
                      trigger={<button> Trigger</button>}
                      position="right center"
                    >
                      <div>Popup content here !!</div>
                    </Popup>
                    <button onClick={handleOpenModal}>Connect Wallet</button>
                  </span>
                ) : (
                  <span>
                    {!minted ? (
                      <div
                        style={{
                          padding: "1em 6em",
                          backgroundColor: "gold",
                          borderRadius: "50px",
                          cursor: "pointer",
                          color: "black",
                          fontWeight: "bolder",
                        }}
                        className="mint-button"
                        onClick={mint}
                      >
                        MINT
                      </div>
                    ) : (
                      <p
                        style={{
                          padding: "1em 6em",
                          backgroundColor: "green",
                          borderRadius: "50px",
                          cursor: "pointer",
                        }}
                      >
                        <a href={newToken}>
                          Mint Successful! 😻Click 🔗 to view
                        </a>
                      </p>
                    )}
                  </span>
                )}
              </div>
              <br></br>
              <span>
                <strong id="pricetag">MINT PRICE: {mintPrice} TLOS</strong>
              </span>
              <span>
                <strong id="pricetag">
                  CURRENT SUPPLY: {totalSupply} KITTIES
                </strong>
              </span>
              <span>
                <strong id="pricetag">MAX SUPPLY: 100 KITTIES</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Hero;
*/
