import React, { useState } from "react";
import { mintNFT, switchNetwork } from "./Web3Client";
import Home from "./Pages/Home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Mint from "./Pages/Mint/Mint";
import Dashboard from "./Components/Dashboard/Dashboard";

function App() {
  const [minted, setMinted] = useState(false);
  //mintNFT.approve('0x7cfF9b449737889f14BC37Ee419763B02d3C1FE2', 10);

  //switch network to telos blockchain

  const [togglenetwork, setToggleNetwork] = useState(false);

  const switchNet = () => {
    switchNetwork().then(console.log("erro"));
    setToggleNetwork(true);
  };

  // mint button functionality on dapps

  const mint = () => {
    mintNFT()
      .then((tx) => {
        console.log(tx);
        setMinted(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            exact
            element={
              <Home switchNetwork={switchNet} togglenetwork={togglenetwork} />
            }
          />
          <Route path="/mint" element={<Mint mint={mint} minted={minted} />} />
          <Route path="/admin" element={<Dashboard />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
