import React from "react";
import "../Project/Project.css";
import one from "../Asset/01.png";
import two from "../Asset/02.png";
import three from "../Asset/03.png";
import four from "../Asset/04.png";

const Project = () => {
  return (
    <div className="project" id="project">
      <div className="project-container">
        <div className="project-heading">PROJECT</div>

        <div className="project-wrapper">
          <div className="projects">
            <div className="image">
              <img src={one} alt="" />
            </div>
            <div className="name">Reinvestment</div>
            <div className="description">
              50% of funds will be reinvested in the Telos Kitties.
            </div>
          </div>
          <div className="projects">
            <div className="image">
              <img src={two} alt="" />
            </div>
            <div className="name">Charity</div>
            <div className="description">
              25% of sales will be allocated to orphanages in Mauritius
            </div>
          </div>
          <div className="projects">
            <div className="image">
              <img src={two} alt="" />
            </div>
            <div className="name">Support</div>
            <div className="description">
              25% of sales will go into my chef career
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
