import React from "react";
import "../FAQs/Faq.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { FaArrowDown } from "react-icons/fa";

const Faq = () => {
  return (
    <div className="faq" id="faq">
      <div className="faq-container">
        <div className="faq-head">FAQs</div>

        <div className="faq-accordion">
          <Accordion
            sx={{
              backgroundColor: "gold",
              margin: "0 auto",
              maxWidth: "700px",
              color: "black",
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              className="panel1"
            >
              <p>What is an NFT?</p>
              <FaArrowDown />
            </AccordionSummary>
            <AccordionDetails>
              NFTs can represent real-world items like artwork and real estate.
              "Tokenizing" these real-world tangible assets makes buying,
              selling, and trading them more efficient while reducing the
              probability of fraud.
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{
              backgroundColor: "gold",
              margin: "0 auto",
              maxWidth: "700px",
              color: "black",
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              className="panel1"
            >
              <p>What is the maximum supply of Telos kitties?</p>
              <FaArrowDown />
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>The maximum supply of Telos Kitties is 100</li>
              </ul>
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{
              backgroundColor: "gold",
              margin: "0 auto",
              maxWidth: "700px",
              color: "black",
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              className="panel1"
            >
              <p>What is the fee for minting a Telos kitty?</p>
              <FaArrowDown />
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  You will be able to mint a Telos kitties on this website by
                  clicking the mint button and paying 50 Telos.
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              backgroundColor: "gold",
              margin: "0 auto",
              maxWidth: "700px",
              color: "black",
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              className="panel1"
            >
              <p>What are the rarities of the Telos Kitties?</p>
              <FaArrowDown />
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  All Telos Kitties are cool but some are cooler than the rest
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{
              backgroundColor: "gold",
              margin: "0 auto",
              maxWidth: "700px",
              color: "black",
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              className="panel1"
            >
              <p>Is the minting random?</p>
              <FaArrowDown />
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>No, the minting process goes in chronological order</li>
              </ul>
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{
              backgroundColor: "gold",
              margin: "0 auto",
              maxWidth: "700px",
              color: "black",
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              className="panel1"
            >
              <p>Why Telos</p>
              <FaArrowDown />
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  I choose telos because its one of the strongest project on
                  this crypto space. the Telos EVM offers NFT creators and
                  curators a welcome relief from the issues that arise when
                  minting on Ethereum and other networks.
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Faq;
