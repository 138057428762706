import React from "react";
import "../Collection/Collection.css";
import kitty1 from "../Asset/kitty1.jpg";
import kitty2 from "../Asset/kitty2.jpg";
import kitty3 from "../Asset/kitty3.jpg";
import kitty4 from "../Asset/kitty4.jpg";
import kitty5 from "../Asset/kitty5.jpg";
const Collection = () => {
  return (
    <div className="collection" id="collection">
      <div className="collection-container">
        <div className="heading">Telos Kitties COLLECTION</div>

        <div className="collection-description">
          Telos Kitties on the Telos blockchain, which is based on the Erc721
          standard, are 100 different Telos Kitties collectibles. TK holders
          have access to special events like NFT claims and community giveaways.
          There will be 100 NFTs available for purchase and 10 NFTs to give
          away.
        </div>

        <div className="more-more-description">
          There are a lot of cat lovers here, and they'll utilize this advantage
          to reach out to the weakest characters in the NFT universe as well as
          those who aren't interested in cryptocurrencies. The road to gaining
          public appeal is already clear given this love of cats.
        </div>

        <div className="images-collection">
          <div className="collection-images">
            <div className="image-container">
              <img src={kitty1} alt="" />
            </div>
            <div className="image-container">
              <img src={kitty2} alt="" />
            </div>
            <div className="image-container">
              <img src={kitty3} alt="" />
            </div>
            <div className="image-container">
              <img src={kitty4} alt="" />
            </div>
            <div className="image-container">
              <img src={kitty5} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Collection;
