import React from "react";
import "../Footer/Footer.css";
import {
  FaTelegram,
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaDiscord,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer" id="contact">
      <div className="footer-head">SOCIALS</div>
      <div className="footer-container">
        <div className="socials">
          <a
            href="https://t.me/+LeRMPPetP7JkYjVk"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTelegram className="link" />
          </a>
          <a
            href="https://twitter.com/NftAreax"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className="link" />
          </a>
          <a
            href="https://www.facebook.com/groups/805108227140614/?ref=share"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook className="link" />
          </a>
        </div>
        <div className="email">
          <p>For more enquiries, email :-</p>
          <a href="mailto:info@gemtexch.com">info@gemtexch.com</a>
        </div>

        <div className="contract-add">
          <p>VERIFIED SMART CONTRACT ADDRESS:</p>
          <span>
            <a
              title="smart contract address"
              href="https://www.teloscan.io/address/0xDceb42c8A66ff5d3c76A23e7c5656c1121594F3e"
            >
              0xDceb42c8A66ff5d3c76A23e7c5656c1121594F3e
            </a>
          </span>
        </div>

        <div className="copyright">2022, TelosKitties NFT ™️</div>
      </div>
    </div>
  );
};

export default Footer;
