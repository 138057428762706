import React from "react";
import "../Intro/Intro.css";

const Intro = () => {
  return (
    <div className="intro">
      <div className="intro-wrapper">
        <div className="intro-blur">
          <div className="intro-content">
            <div className="intro-heading glow">
              Welcome to the
              <br /> Telos Kitties Box
            </div>
            <div className="intro-description">
              100 Kitties on the Telos Blockchain 😻
            </div>
            <div className="intro-mint-link">
              <a href="/mint">Mint Here</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
