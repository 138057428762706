import React, { useState } from "react";
import "../Nav/Nav.css";
import { useLocation } from "react-router-dom";
import { Link } from "react-scroll";

const Nav = ({ switchNetwork, togglenetwork }) => {
  const [navwrapper, setNavWrapper] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 70) {
      setNavWrapper(true);
    } else {
      setNavWrapper(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  const location = useLocation();

  const Toggle = () => {
    return (
      <div className="switch">
        <a href="/">Home</a>
      </div>
    );
  };

  return (
    <div className={navwrapper ? "navwrapper" : "navwrapper active"}>
      <div className="nav-container">
        <div className="logos">
          <a href="/">
            <div className="logo-picture">TK</div>
            <div className="logo-name">Telos Kitties</div>
          </a>
        </div>

        {/* if the page location is anywhere aside the homepage which is the "/" directory, the react-scroll which houses the links would be invisible */}

        {location.pathname === "/" ? (
          <div className="react-scroll">
            <Link to="collection" activeClass="active" spy={true}>
              COLLECTION
            </Link>

            <Link to="project" spy={true}>
              PROJECT
            </Link>
            <Link to="faq" spy={true}>
              FAQs
            </Link>

            <Link to="contact" spy={true}>
              CONTACT
            </Link>
          </div>
        ) : null}

        <div className="mint-directory">
          {location.pathname === "/" ? (
            <div className="mint-btn">
              <a href="/mint">Mint</a>
            </div>
          ) : (
            <Toggle />
          )}
        </div>
      </div>
    </div>
  );
};

export default Nav;
