import React from "react";
import Hero from "../../Components/Hero/Hero";
import Nav from "../../Components/Nav/Nav";

const Mint = ({ minted, mint }) => {
  return (
    <div className="mint">
      <Nav />
      <Hero mint={mint} minted={minted} />
    </div>
  );
};

export default Mint;
