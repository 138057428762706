import React from "react";
import "./Home.css";
import Nav from "../../Components/Nav/Nav";
import Intro from "../../Components/Intro/Intro";
import Collection from "../../Components/Collection/Collection";
import Project from "../../Components/Project/Project";
// import Team from "../../Components/Team/Team";
import Footer from "../../Components/Footer/Footer";
import FAQ from "../../Components/FAQs/Faq";

const Home = ({ switchNetwork, togglenetwork }) => {
  return (
    <div className="home">
      <Nav switchNetwork={switchNetwork} togglenetwork={togglenetwork} />
      <Intro />

      <Collection />
      <Project />
      {/*
       <Team /> */}
      <FAQ />
      <Footer />
    </div>
  );
};

export default Home;
